<script setup>
import { onMounted, reactive, ref } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
const route = useRoute();
let invoices = ref([]);
const store = useStore();
let org_id = route.query.org;

const current_period = ref(null),
    loaded = ref(null),
    total_requests = ref(0),
    total_cost = ref(0),
    key_usage = reactive([]);

onMounted(() => {
    fetch(`/api/org/${org_id}/invoices`)
        .then((r) => r.json())
        .then((data) => {
            invoices.value = data.invoices;
            if (invoices.value.length > 0) {
                store.state.selected_invoice = invoices.value[0];
                setInvoice(invoices.value[0]);
            }
        })
        .catch((err) => {
            console.error(err);
        });
});

const selectInvoice = (e) => {
    setInvoice(e.target.value);
};
const setInvoice = (period) => {
    return fetch(`/api/org/${org_id}/invoice/${period}`)
        .then((r) => {
            return r.json();
        })
        .then((invoice_dets) => {
            current_period.value = period;
            store.state.invoice_details = invoice_dets;
            loaded.value = true;

            if (store.state.invoice_details.v == 0) {
                for (let key in store.state.invoice_details.usage.keys) {
                    key_usage.push(key);
                }
            } else if (store.state.invoice_details.v == 1) {
                for (let key in store.state.invoice_details.key_data.keys) {
                    key_usage.push(key);
                }

                for (let i of store.state.invoice_details.invoices) {
                    total_requests.value += i.requests;
                    total_cost.value += i.cost;
                }
            }
        });
};
</script>

<template>
    <div class="wrap dashboard_root flex_col">
        <h2 class="ta_center">Invoices</h2>
        <div v-if="$store.state.invoice_details">
            <div v-if="invoices.length == 0">
                <p class="ta_center">No invoices yet. Check back nect month 📭</p>
            </div>
            <div v-if="$store.state.invoice_details == 0">
                <div class="ump-2-invoice flex_col" v-if="loaded">
                    <div class="select_rivet-invoice ta_center flex_col">
                        <p>Select a Billing Period</p>
                        <select @select="selectInvoice" name="invoice period" id="">
                            <option v-for="item in invoices" :key="item" :value="item">{{ item }}</option>
                        </select>
                    </div>

                    <ul class="flex_col">
                        <li v-for="key in key_usage" :key="key">{{ key }}: {{ $store.state.invoice_details.usage.keys[key] }}</li>
                    </ul>
                    <div class="tth-rivet">
                        <p>Total requests: {{ $store.state.invoice_details.usage.total }}</p>
                        <p>Credits Applied: {{ $store.state.invoice_details.usage.credits }}</p>
                        <p>Cost: {{ ($store.state.invoice_details.cost / 100).toFixed(2) }}</p>
                    </div>
                </div>
            </div>
            <div v-else-if="$store.state.invoice_details.v == 1">
                <div class="ump-2-invoice flex_col" v-if="loaded">
                    <div class="select_rivet-invoice ta_center flex_col">
                        <p>Select a Billing Period</p>
                        <select @select="selectInvoice" name="invoice period" id="">
                            <option v-for="item in invoices" :key="item" :value="item">{{ item }}</option>
                        </select>
                    </div>

                    <table>
                        <thead>
                            <th>Plan</th>
                            <th>Requests</th>
                            <th>Billed</th>
                            <th>Cost</th>
                        </thead>
                        <tbody v-if="$store.state.invoice_details.invoices">
                            <tr v-for="i in $store.state.invoice_details.invoices" :key="i">
                                <td>
                                    {{ i.plan }}
                                </td>
                                <td>
                                    {{ i.requests }}
                                </td>
                                <td>{{ i.billed_units.toLocaleString() }} x {{ i.unit_name }}</td>
                                <td>${{ (i.cost / 100).toFixed(2) }}</td>
                            </tr>
                            <tr>
                                <td><p>Total</p></td>
                                <td>{{ total_requests.toLocaleString() }}</td>
                                <td></td>
                                <td>{{ (total_cost / 100).toFixed(2) }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <ul class="flex_col">
                        <li v-for="key in key_usage" :key="key">{{ key }}: {{ $store.state.invoice_details.key_data.keys[key] }}</li>
                    </ul>
                </div>
            </div>
            <div v-else></div>
        </div>
        <div v-else class="ajax_loader ta_center">
            <img width="40" src="https://res.cloudinary.com/ferventdev/image/upload/v1655149185/rivet.cloud/1488_t1b6v8.gif" alt="" />
        </div>
    </div>
</template>

<style scoped lang="scss">
h2 {
    color: $offblack;
    padding: 0px;
}
.select_rivet-invoice {
    gap: 0.7rem 0;
    align-items: center;
    select {
        width: 12rem;
        padding: 0.5rem 1.3rem;
        font-size: 1.1rem;
        background: $blue;
        border-radius: 4px;
        color: white;
    }
}
.ump-2-invoice {
    width: 85%;
    margin: 0 auto;
    gap: 2.4rem 0px;
    @include media("<=tablet") {
        width: 90%;
    }
    @include media("<=phone-tab") {
        width: 100%;
    }
    table {
        width: 100%;
        border-radius: 0.8rem;
        box-shadow: inset 1px 1px 19px 1px #ececec;
        border-spacing: 2rem 0.7rem;
        @include media("<=phone-tab") {
            border-spacing: 1rem 0.5rem;
        }
        td,
        th {
            text-align: center;
            padding: 0.5rem 0rem;
        }
        th {
            font-size: 1.2em;
        }
    }
}

ul {
    list-style: none;
    gap: 1rem;
    align-items: center;
    li {
        font-size: 1.1em;
    }
}
.tth-rivet {
    @include flex(none, center, 1rem);
}
</style>
